import React from 'react';
import services from '../../data/services';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Services: React.FC = () => {
  const responsiveSettngs = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  }

  return(
    <div className='h-full animate-fade animate-once animate-duration-[2000ms] animate-ease-in-out'>
      <div className='h-full mx-auto max-w-7xl p-6 md:px-8 lg:px-10 overview-content content-center'>
        <div className='pb-3 relative'>
          <Carousel infinite={true} arrows={true} showDots={false} slidesToSlide={1} renderDotsOutside={false}
            responsive={responsiveSettngs} swipeable={true} rtl={false}>
            {
              services.map((service) => {
                return (
                  <div key={`${service.key}`} className='max-w rounded overflow-hidden shadow-lg bg-white lg:max-w-sm'>
                    <img className='w-full' src={service.imagePath} alt={service.alt} />
                    <div className='px-6 py-4 border-t border-gray-100'>
                      <div className='font-bold text-xl mb-2'>{service.title}</div>
                      <div className='text-gray-700 text-base h-24'>{service.description}</div>
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Services);
