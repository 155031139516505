import { tProject } from '../types';
import i18n from '../i18n';

const WIDTH = 1680;
const HORIZONTAL_HEIGHT = 935;
const VERTICAL_HEIGHT = 2380;

const projects: tProject[] = [
  {
    key: 'current-project',
    name: i18n.t('currentProjecs'),
    projects: [
      {
        id: 'ca83d893-a0e8-4f06-b6f6-4aa296b4662f',
        title: 'Taman Saujana Idaman II - 4C',
        subTitle: 'Rumah Teres 2 Tingkat',
        description: 'Designed for contemporary living with spacious layouts. Nestled in a lively community, residents can benefit from convenient proximity to local amenities, schools and tertiary institutions. Ideal for personal residence, convenient access to highway and main roads, and budget-friendly payments starting at RM1.6K.',
        location: 'Kubang Gajah, Perlis',
        gdv: '29000000',
        contract: '2025-2027',
        tags: ['DoubleStorey', 'RumahTeres', 'TeresHouse'],
        cover: '/images/projects/covers/taman-saujana-idaman.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-1.png',
            src: 'ds-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-2.png',
            src: 'ds-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-fp-gf.png',
            src: 'ds-fp-gf-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-fp-ff.png',
            src: 'ds-fp-ff-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-3.png',
            src: 'ds-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-24.png',
            src: 'ds-4-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: 'cf517f80-d4c0-45c0-bf79-6e06df37050e',
        title: 'Taman Sri Gunung',
        subTitle: '',
        description: 'Luxurious land area up to 35\'x 80\' exclusive to only 15 homeowners! Freehold & Malay reserved title. Located in a well-planned integrated development with only 11 min access to bustling city centre of Kangar and city\'s amenities at your doorstep',
        location: 'Kangar, Perlis',
        gdv: '6700000',
        contract: '2024-2026',
        tags: ['SemiD', 'Kangar', '1Storey', 'Berkembar'],
        cover: '/images/projects/covers/taman-sri-gunung.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'bungalow-fp.png',
            src: 'bungalow-fp-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'bungalow-perspective-view-1.png',
            src: 'bungalow-perspective-view-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'bungalow-perspective-view-2.png',
            src: 'bungalow-perspective-view-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'bungalow-perspective-view-3.png',
            src: 'bungalow-perspective-view-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-fp-1-thumbnail.png',
            src: 'semid-fp-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-perspective-view-1.png',
            src: 'semid-perspective-view-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-fp-1-thumbnail.png',
            src: 'semid-fp-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-perspective-view-2.png',
            src: 'semid-perspective-view-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-perspective-view-3.png',
            src: 'semid-perspective-view-3-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '8a471679-0aef-4f33-83b8-691b8a947a7c',
        title: 'Taman Saujana Bintong PPAM',
        subTitle: '',
        description: 'Another Project for Goverment Skim PPAM super subsidies to goverment servants.',
        location: 'Kangar, Perlis',
        gdv: '7700000',
        contract: '2024-2026',
        tags: ['PPAM'],
        cover: '/images/projects/covers/ppam.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-1.png',
            src: 'ppam-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-2.png',
            src: 'ppam-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-3.png',
            src: 'ppam-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-4.png',
            src: 'ppam-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-5.png',
            src: 'ppam-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ppam-6.png',
            src: 'ppam-6-thumbnail.png',
            tags: [],
          },
        ]
      }
    ]
  },
  {
    key: 'future-project',
    name: i18n.t('futureProjecs'),
    projects: [
      {
        id: '5a1d3a82-b85b-45a3-aaf8-24ab2abe515f',
        title: 'Taman Sri Gunung II',
        subTitle: '',
        description: 'An exciting new property project is coming soon. Stay tuned for details!',
        location: 'Kangar, Perlis',
        gdv: '14000000	',
        contract: '2026-2028',
        cover: '/images/projects/covers/taman-sri-gunung.png',
        tags: ['SemiD', 'Kangar', '1Storey', 'Berkembar'],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-1.png',
            src: 'rb-1s-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-2s-fp-ff-1.png',
            src: 'rb-2s-fp-ff-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-2.png',
            src: 'rb-1s-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-3.png',
            src: 'rb-1s-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-4.png',
            src: 'rb-1s-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-fp-1.png',
            src: 'rb-1s-fp-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-1s-5.png',
            src: 'rb-1s-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-2s-1.png',
            src: 'rb-2s-1-thumbnail.png',
            tags: [],
          },

          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'rb-2s-fp-gf-1.png',
            src: 'rb-2s-fp-gf-1-thumbnail.png',
            tags: [],
          }
        ]
      }
    ]
  },
  {
    key: 'completed-project',
    name: i18n.t('completedProjecs'),
    projects: [
      {
        id: '2e7b56d5-1f66-4764-afa8-30dbb61d7162',
        title: 'Taman Saujana Idaman - Phase I',
        subTitle: 'Rumah Teres 1.5 Tingkat',
        description: 'We are proud to announce the successful completion of Phases 1A, 1B, 1C, and Phase D of our project. Each phase represents a milestone in our commitment to delivering exceptional quality, innovative design, and sustainable solutions. With meticulous attention to detail and a focus on meeting the needs of our community, these completed phases stand as a testament to our dedication to excellence in every aspect of development.',
        location: 'Kubang Gajah, Perlis',
        gdv: '78000000',
        contract: '2016-2019',
        tags: ['1.5 Storey'],
        cover: '/images/projects/covers/taman-saujana-idaman.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-terrance-1.png',
            src: 'ss-terrance-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-terrance-2.png',
            src: 'ss-terrance-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-1.png',
            src: 'ds-terrance-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-2.png',
            src: 'ds-terrance-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-3.png',
            src: 'ds-terrance-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-4.png',
            src: 'ds-terrance-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-5.png',
            src: 'ds-terrance-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-6.png',
            src: 'ds-terrance-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ds-terrance-7.png',
            src: 'ds-terrance-7-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-1.png',
            src: 'semid-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-2.png',
            src: 'semid-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-3.png',
            src: 'semid-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-4.png',
            src: 'semid-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-5.png',
            src: 'semid-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-6.png',
            src: 'semid-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-7.png',
            src: 'semid-7-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-8.png',
            src: 'semid-8-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-9.png',
            src: 'semid-9-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'semid-10.png',
            src: 'semid-10-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '74d4956c-3c1e-4876-9cfc-7ebcf578dd73',
        title: 'Taman Saujana Idaman - Phase II',
        subTitle: 'Rumah Teres 1.5 Tingkat',
        description: 'We are proud to announce the successful completion of Phases 4B and 4G of our project. Each phase represents a milestone in our commitment to delivering exceptional quality, innovative design, and sustainable solutions. With meticulous attention to detail and a focus on meeting the needs of our community, these completed phases stand as a testament to our dedication to excellence in every aspect of development.',
        location: 'Kubang Gajah, Perlis',
        gdv: '39000000  ',
        contract: '2021-2024',
        tags: ['1.5 Storey'],
        cover: '/images/projects/covers/taman-saujana-idaman-2.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-1.png',
            src: '1-5-storey-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-2.png',
            src: '1-5-storey-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-3.png',
            src: '1-5-storey-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-4.png',
            src: '1-5-storey-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-5.png',
            src: '1-5-storey-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '1-5-storey-6.png',
            src: '1-5-storey-6-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '63336200-8825-427f-8501-07f0e6907d16',
        title: 'Taman Saujana Idaman - Phase II',
        subTitle: 'Rumah Teres 1 Tingkat',
        description: 'We are proud to announce the successful completion of Phases 4A of our project. Each phase represents a milestone in our commitment to delivering exceptional quality, innovative design, and sustainable solutions. With meticulous attention to detail and a focus on meeting the needs of our community, these completed phases stand as a testament to our dedication to excellence in every aspect of development.',
        location: 'Kubang Gajah, Perlis',
        gdv: '24000000',
        contract: '2019-2022',
        tags: ['Single Storey'],
        cover: '/images/projects/covers/taman-saujana-idaman-2.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-fp.png',
            src: 'ss-fp-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-final-view-1.png',
            src: 'ss-final-view-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-final-view-2.png',
            src: 'ss-final-view-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-final-view-3.png',
            src: 'ss-final-view-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-living-room-1.png',
            src: 'ss-living-room-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-living-room-2.png',
            src: 'ss-living-room-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-living-room-3.png',
            src: 'ss-living-room-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-dining-area-1.png',
            src: 'ss-dining-area-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-kitchen-1.png',
            src: 'ss-kitchen-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'ss-master-room-1.png',
            src: 'ss-master-room-1-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '97a95f9d-3687-4e43-b32d-711806642698',
        title: 'Taman Saujana Idaman',
        subTitle: '68K Terrance House',
        description: 'We are proud to announce the successful completion of Phases 1E1 and 1E3 of our project. Each phase represents a milestone in our commitment to delivering exceptional quality, innovative design, and sustainable solutions. With meticulous attention to detail and a focus on meeting the needs of our community, these completed phases stand as a testament to our dedication to excellence in every aspect of development.',
        location: 'Kubang Gajah, Perlis',
        gdv: '8200000',
        contract: '2018-2022',
        tags: ['Low Cost', '68k'],
        cover: '/images/projects/covers/taman-saujana-idaman.png',
        images: [
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-flyer-1.png',
            src: '68k-terrance-flyer-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-flyer-2.png',
            src: '68k-terrance-flyer-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-1.png',
            src: '68k-terrance-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-2.png',
            src: '68k-terrance-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-3.png',
            src: '68k-terrance-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-4.png',
            src: '68k-terrance-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: '68k-terrance-fp.png',
            src: '68k-terrance-fp-thumbnail.png',
            tags: [],
          }
        ]
      },
    ]
  },
  {
    key: 'construction-project',
    name: i18n.t('constructionProjecs'),
    projects: [
      {
        id: '9292d377-d87e-4ea2-abe5-bcbbc1e534e0',
        title: 'Bridge Jalan Suka Menanti',
        subTitle: '',
        description: 'We are proud to announce the completion of the bridge at Jalan Suka Menanti, enhancing connectivity and accessibility with a focus on safety, durability, and sustainability.',
        location: 'Alor Setar, Kedah',
        gdv: '25000000',
        contract: '2015-2018',
        cover: '/images/projects/covers/jalan-suka-menanti-bridge.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmb-1.png',
            src: 'jsmb-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmb-2.png',
            src: 'jsmb-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmb-3.png',
            src: 'jsmb-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmb-4.png',
            src: 'jsmb-4-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '31265ed3-b764-4205-b438-1c15640ee5a9',
        title: 'Taman Universiti Jaya',
        subTitle: '',
        description: 'Taman Universiti Jaya 2A & 2B',
        location: 'Semeling, Kedah',
        gdv: '36000000',
        contract: '2018-2021',
        cover: '/images/projects/covers/taman-universiti-jaya.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-1.png',
            src: 'tuj-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-2.png',
            src: 'tuj-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-3.png',
            src: 'tuj-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tuj-4.png',
            src: 'tuj-4-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '212b150d-b489-4c09-9d8d-c0ed88332aae',
        title: 'Tertiary Irrigation System',
        subTitle: '',
        description: 'Tertiary Irrigation System, Mada Block 4, 4A & 5',
        location: 'Alor Setar, Kedah',
        gdv: '21500000',
        contract: '2015-2017',
        cover: '/images/projects/covers/tertiary-irrigation-system.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tis-1.png',
            src: 'tis-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tis-2.png',
            src: 'tis-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tis-3.png',
            src: 'tis-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tis-4.png',
            src: 'tis-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'tis-5.png',
            src: 'tis-5-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: 'de769ac8-5895-4e48-82d7-68d55a256901',
        title: 'Upgrading Jalan Suka Menanti-K137',
        subTitle: '',
        description: 'Upgrading of Jalan Suka Menanti-K137.',
        location: 'Alor Setar, Kedah',
        gdv: '65300000',
        contract: '2018-2021',
        cover: '/images/projects/covers/jalan-suka-menanti-k137.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-1.png',
            src: 'jsmk137-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-2.png',
            src: 'jsmk137-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-3.png',
            src: 'jsmk137-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-4.png',
            src: 'jsmk137-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-5.png',
            src: 'jsmk137-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-6.png',
            src: 'jsmk137-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-7.png',
            src: 'jsmk137-7-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-8.png',
            src: 'jsmk137-8-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-9.png',
            src: 'jsmk137-9-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-10.png',
            src: 'jsmk137-10-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-11.png',
            src: 'jsmk137-11-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-12.png',
            src: 'jsmk137-12-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-13.png',
            src: 'jsmk137-13-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-14.png',
            src: 'jsmk137-14-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-15.png',
            src: 'jsmk137-15-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-16.png',
            src: 'jsmk137-16-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-17.png',
            src: 'jsmk137-17-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-18.png',
            src: 'jsmk137-18-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-19.png',
            src: 'jsmk137-19-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-20.png',
            src: 'jsmk137-20-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-21.png',
            src: 'jsmk137-21-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-22.png',
            src: 'jsmk137-22-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-23.png',
            src: 'jsmk137-23-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'jsmk137-24.png',
            src: 'jsmk137-24-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: '4da314db-ad86-4415-8f35-bc13e994016d',
        title: 'Hospital Pendang',
        subTitle: '',
        description: 'Building Khas & Infrastructure, Road and Drainage of Hospital Pendang',
        location: 'Pendang, Kedah',
        gdv: '8750000',
        contract: '2018-2020',
        cover: '/images/projects/covers/hospital-pendang.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-1.png',
            src: 'hp-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-2.png',
            src: 'hp-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-3.png',
            src: 'hp-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-4.png',
            src: 'hp-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-5.png',
            src: 'hp-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-6.png',
            src: 'hp-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'hp-7.png',
            src: 'hp-7-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: 'ef915518-559c-480b-9381-844657d07487',
        title: 'Masjid Putra',
        subTitle: '',
        description: 'Upgrade Masjid Putra',
        location: 'Wilayah Persekutuan Putrajaya',
        gdv: '12600000',
        contract: '2020-2022',
        cover: '/images/projects/covers/masjid-putra.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-1.png',
            src: 'mp-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-2.png',
            src: 'mp-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-3.png',
            src: 'mp-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-4.png',
            src: 'mp-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-5.png',
            src: 'mp-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-6.png',
            src: 'mp-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-7.png',
            src: 'mp-7-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-8.png',
            src: 'mp-8-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-9.png',
            src: 'mp-9-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-10.png',
            src: 'mp-10-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-11.png',
            src: 'mp-11-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-12.png',
            src: 'mp-12-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-13.png',
            src: 'mp-13-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-14.png',
            src: 'mp-14-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'mp-15.png',
            src: 'mp-15-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT  * 0.5,
            caption: '',
            original: 'mp-16.png',
            src: 'mp-16-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: 'b8102af0-28a1-4df0-9e99-e2f959b4655f',
        title: 'Residensi Akasia Jubilee',
        subTitle: '',
        description: 'We are proud to announce the completion of Residensi Akasia Jubilee, a thoughtfully designed residential development offering modern living, comfort, and community in a vibrant setting.',
        location: 'Kuala Lumpur',
        gdv: '96000000',
        contract: '2020-2023',
        cover: '/images/projects/covers/residensi-akasia-jubilee.png',
        tags: [''],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-1.png',
            src: 'raj-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-2.png',
            src: 'raj-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-3.png',
            src: 'raj-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-4.png',
            src: 'raj-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-5.png',
            src: 'raj-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-6.png',
            src: 'raj-6-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-7.png',
            src: 'raj-7-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-8.png',
            src: 'raj-8-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-9.png',
            src: 'raj-9-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-10.png',
            src: 'raj-10-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-11.png',
            src: 'raj-11-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-12.png',
            src: 'raj-12-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: VERTICAL_HEIGHT * 0.5,
            caption: '',
            original: 'raj-13.png',
            src: 'raj-13-thumbnail.png',
            tags: [],
          }
        ]
      },
      {
        id: 'f1872984-8874-4520-a5af-e613a14fc2d0',
        title: 'Taiping Wakaf Hotel',
        subTitle: '',
        description: 'We are proud to announce the completion of Taiping Wakaf Hotel, a modern retreat offering comfort, convenience, and exceptional hospitality in the heart of Taiping.',
        location: 'Taiping, Perak',
        gdv: '',
        contract: '',
        cover: '/images/projects/covers/taiping-wakaf-hotel.png',
        tags: ['completed'],
        images: [
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-1.png',
            src: 'twh-1-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-2.png',
            src: 'twh-2-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-3.png',
            src: 'twh-3-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-4.png',
            src: 'twh-4-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-5.png',
            src: 'twh-5-thumbnail.png',
            tags: [],
          },
          {
            width: WIDTH * 0.5,
            height: HORIZONTAL_HEIGHT * 0.5,
            caption: '',
            original: 'twh-6.png',
            src: 'twh-6-thumbnail.png',
            tags: [],
          }
        ]
      }
    ]
  }
]

export default projects;