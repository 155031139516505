import React from 'react';
import commaNumber from 'comma-number';
import { Tag } from '../../../components';
import { tProjectDetails } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import i18n from '../../../i18n';

interface IProjectDetails {
  projectType: string|undefined;
  projectDetails: tProjectDetails|undefined;
}

const ProjectDetails: React.FC<IProjectDetails> = ({ projectType, projectDetails }: IProjectDetails) => {


  if(projectType !== undefined && projectDetails !== undefined) {
    const [index, setIndex] = React.useState(-1);
    const projectImages = (!projectDetails) ? [] : projectDetails.images.map((image) => {
      const prefixed = `/images/projects/${projectType}/${projectDetails.id}`;
      return {
        ...image,
        src: `${prefixed}/thumbnails/${image.src}`,
        original: `${prefixed}/${image.original}`,
      }
    })

    const renderGDV = (value: string|undefined ) => {
      if (value) {
        return (
          <p className='uppercase my-2 text-md lg:text-xl'>
            <span className='font-extrabold mr-2'>{ i18n.t('gdv') }:</span>
            <span className='text-md lg:text-xl'>MYR { commaNumber(parseFloat(value).toFixed(2)) }</span>
          </p>
        )
      }
    }

    const renderContract = (value: string|undefined ) => {
      if (value) {
        return (
          <p className='uppercase my-2 text-md lg:text-xl'>
            <span className='font-extrabold mr-2'>{ i18n.t('contract') }:</span>
            <span className='text-md lg:text-xl'>{ value }</span>
          </p>
        )
      }
    }

    const responsiveColumns = (containerWidth: number) => {
      return (containerWidth < 400) ? 2 : 3;
    }

    return (
      <div>
        <div className='uppercase font-extrabold my-3 text-lg lg:text-2xl'>{projectDetails.title}</div>
        <div className='my-3'>
          {
            (projectDetails.location === '') ? null : <Tag icon={<FontAwesomeIcon icon={['fas', 'location-dot']} />} textSize='text-sm' bgColor='bg-red-700' fontColor='text-white' value={projectDetails.location} />
          }
          {
            projectDetails.tags.map((tag, index) => {
              return <Tag key={`tag-${index}`} icon={<FontAwesomeIcon icon={['fas', 'hashtag']} />} textSize='text-sm' bgColor='bg-blue-700' fontColor='text-white' value={tag} />
            })
          }
        </div>
        { renderGDV(projectDetails.gdv) }
        { renderContract(projectDetails.contract) }
        <div className='mt-2 mb-5'>
          <p className='font-extrabold uppercase text-md lg:text-xl'>{ i18n.t('description') }:</p>
          <p className='text-md lg:text-xl'>{projectDetails.description}</p>
        </div>
        <PhotoAlbum photos={projectImages} layout='masonry' breakpoints={[300, 600, 1200]}
          columns={responsiveColumns} onClick={({ index }) => setIndex(index)}
          componentsProps={{
            imageProps: { className: 'round-lg' }
          }}/>
        <Lightbox
          slides={projectImages}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </div>
    );
  } else {
    return (<div><h1>No Data</h1></div>);
  }
}

export default ProjectDetails;
