import React from 'react';
import routesConfig from './config';
import { tRoutes } from '../types'
import { BrowserRouter, Route, Routes } from 'react-router'
import { BaseLayout } from '../components';
import Pages from '../pages';

const WebRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {
          routesConfig.map((route: tRoutes) => {
            return (
              <Route key={route.key} path={route.url || ''} element={(
                <BaseLayout>
                  { route.component && Pages[route.component] }
                </BaseLayout>
              )}/>
            )
          })
        }
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
