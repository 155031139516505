import React from 'react';
import i18n from '../../i18n';
import Carousel from 'react-multi-carousel';

const MissionVision: React.FC = () => {
  const responsiveSettngs = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }

  return(
    <div className='h-full animate-fade animate-once animate-duration-[2000ms] animate-ease-in-out'>
      <div className='h-full mx-auto max-w-7xl p-6 md:px-8 lg:px-10 overview-content content-center'>
        <div className='pb-3 relative'>
          <Carousel arrows={false} showDots={true} slidesToSlide={1} renderDotsOutside={true}
            dotListClass='dot-list-outside'
            responsive={responsiveSettngs} autoPlaySpeed={3000} swipeable={true}>
            <div className='bg-white shadow-md rounded-2xl p-10 max-h-96 overflow-auto lg:h-104'>
              <div className='text-center text-2xl font-bold pb-3 lg:pb-6 lg:text-5xl'>
                { i18n.t('ourMission') }
              </div>
              <div className='text-justify content-center text-md lg:text-3xl'>
                { i18n.t('ourMissionContent') }
              </div>
            </div>
            <div className='bg-white shadow-md rounded-2xl p-10  max-h-96 overflow-auto lg:h-104'>
              <div className='text-center text-2xl font-bold pb-3 lg:pb-6 lg:text-5xl'>
                { i18n.t('ourVision') }
              </div>
              <div className='text-justify content-center text-md lg:text-3xl'>
                { i18n.t('ourVisionContent') }
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MissionVision);
