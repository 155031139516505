import React from 'react';

const Error404: React.FC = () => {
  return (
    <div className='h-full animate-fade animate-once animate-duration-[2000ms] animate-ease-in-out'>
      <div className='h-full mx-auto max-w-7xl flex justify-center items-center'>
        <img src='/images/404.png' className='animate-shake animate-once h-80 lg:h-104 '/>
      </div>
    </div>
  )
}

export default React.memo(Error404);
