import React from 'react';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactUs: React.FC = () => {
  return(
    <div className='mx-auto h-full w-full max-w-7xl p-7 px-2 md:p-6 lg:p-6 animate-fade animate-delay-200 animate-once'>
      <div className="grid grid-rows-1 grid-cols-12 gap-4 h-full">
        <div className='py-10 col-span-12 lg:col-span-8'>
          <iframe
            className='shadow-lg rounded-lg h-full w-full'
            src="https://maps.google.com/maps?q=Taman%20Saujana%20Idaman%20Sales%20Gallery&t=k&z=15&ie=UTF8&iwloc=&output=embed"
            loading="lazy" />
        </div>
        <div className='py-10 col-span-12 lg:col-span-4 animate-fade animate-delay-200 animate-once'>
          <div className='bg-white px-5 py-8 shadow-lg rounded-lg h-full w-full text-center'>
            <div className='text-4xl pb-3 font-bold uppercase'>{ i18n.t('getInTouch') }</div>
            <div className='text-md  pb-3'>
              { i18n.t('getInTouchDescription') }
            </div>
            <div className='text-xl pt-3 font-bold uppercase text-primary'>
              { i18n.t('address') }:
            </div>
            <div className='text-md pb-2'>
              <p>
                <strong>Taman Saujana Idaman Sales Gallery</strong><br/>
                KM6, Jalan Kangar - Arau,<br/>
                Kubang Gajah, 02600 Arau,<br/>
                Perlis.<br/>
              </p>
            </div>
            <div className='text-lg pt-3 font-bold uppercase text-primary'>
              { i18n.t('contactNumber') }:
            </div>
            <div className='text-md pb-2'>
              <p>+604-976 2554</p>
            </div>
            <div className='text-lg pt-3 font-bold uppercase text-primary'>
              { i18n.t('emailAddress') }:
            </div>
            <div className='text-md pb-2'>
              <p>tamansaujanaidaman@gmail.com</p>
            </div>
            <div className='text-lg py-4 font-bold uppercase text-primary'>
              { i18n.t('socialMedia') }:
            </div>
            <div className='text-md pb-2'>
              <a className='px-2 facebook-icon' href='https://www.facebook.com/detikventures' target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook-square']} size={'2x'} />
              </a>
              <a className='px-2' href='https://www.tiktok.com/@detikventure' target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'tiktok']} size={'2x'} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default React.memo(ContactUs);
