import { tService } from '../types';
import i18n from '../i18n';

const services: tService[] = [
  {
    key: 'house-loan-key',
    imagePath: '/images/services/mortgage.png',
    alt: i18n.t('houseLoan'),
    title: i18n.t('houseLoanApply'),
    description: i18n.t('houseLoanApplyDescription')
  },
  {

    key: 'legal-consultant-key',
    imagePath: '/images/services/legal-consultant.png',
    alt: i18n.t('legalConsultant'),
    title: i18n.t('legalFeeConsultant'),
    description: i18n.t('legalFeeConsultantDescription')
  },
  {
    key: 'project-briefying-key',
    imagePath: '/images/services/project-briefying.png',
    alt: i18n.t('projectBriefying'),
    title: i18n.t('projectBriefying'),
    description: i18n.t('projectBriefyingDescription'),
  },
  {
    key: 'kwsp-key',
    imagePath: '/images/services/kwsp.png',
    alt: i18n.t('epfWithdrawal'),
    title: i18n.t('epfWithdrawal'),
    description: i18n.t('epfWithdrawalDescription')
  },
  // {
  //   key: 'myhome-key',
  //   imagePath: '/images/services/myhome.png',
  //   alt: i18n.t('myHome'),
  //   title: i18n.t('myHomeScheme'),
  //   description: i18n.t('myHomeSchemeDescription')
  // },
  {
    key: 'sjkp-key',
    imagePath: '/images/services/sjkp.png',
    alt: i18n.t('sjkp'),
    title: i18n.t('sjkp'),
    description: i18n.t('sjkpDescription')
  },
  {
    key: 'ppam-key',
    imagePath: '/images/services/ppam.png',
    alt: i18n.t('ppam'),
    title: i18n.t('ppamScheme'),
    description: i18n.t('ppamSchemeDescription')
  },
]

export default services
