import React from 'react';
import navigations from '../../../data/navigations';
import { tNavigations, tSubNavigations } from '../../../types';
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline';

interface IMobileMenuBar {
  mobileMenuOpen: boolean
  setMobileMenuOpen: (isOpen: boolean) => void
}

const MobileMenuBar: React.FC<IMobileMenuBar> = ({mobileMenuOpen, setMobileMenuOpen}: IMobileMenuBar) => {
  return (
    <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
      <div className="fixed inset-0 z-10" />
      <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-7 px-2 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Detik Ventures</span>
            <img alt="Detik Ventures" src="/images/logo/logo.png" className="h-10 w-auto md:h-12 lg:h-12" />
          </a>
          <button
            type="button"
            onClick={() => setMobileMenuOpen(false)}
            className="-m-2.5 rounded-md p-2.5 px-6 text-gray-700">
            <span className="sr-only">Close menu</span>
            <XMarkIcon aria-hidden="true" className="size-6" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              {
                navigations.map((route: tNavigations) => {
                  if (route.subNavigations.length == 0) {
                    return (
                      <a key={`link-${route.key}`} href={route.url} className='-mx-3 block rounded-lg px-3 py-2 text-lg font-semibold text-gray-900 hover:bg-gray-50'>
                        {route.title}
                      </a>
                    )
                  } else {
                    return (
                      <Disclosure key={`link-${route.key}`} as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-lg font-semibold text-gray-900 hover:bg-gray-50">
                          { route.title }
                          <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">
                          {
                            (route.subNavigations.map((subRoute: tSubNavigations) => {
                              return (
                                <DisclosureButton key={`nav-${subRoute.key}`} as='a'  href={subRoute.url}
                                  className="block rounded-lg py-2 pl-6 pr-3 text-lg font-semibold text-gray-900 hover:bg-gray-50">
                                  { subRoute.title }
                                </DisclosureButton>
                              )
                            }))
                          }
                        </DisclosurePanel>
                      </Disclosure>
                    )
                  }
                })
              }
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
    // <PopoverGroup className='hidden md:flex md:gap-x-12 lg:flex lg:gap-x-12'>
    //   {
    //     navigations.map((route: tNavigations) => {
    //       if (route.subNavigations.length == 0) {
    //         return (
    //           <a key={`link-${route.key}`}href={route.url} className={clsx('text-lg font-bold text-gray-900 py-2 px-4', `btn-${route.component.toLowerCase()}`)}>
    //             {route.title}
    //           </a>
    //         )
    //       } else {
    //         return (
    //           <Popover key={`popover-${route.key}`} className="relative">
    //             <PopoverButton className="flex items-center gap-x-1 text-lg font-bold text-gray-900 py-2 px-4">
    //               {route.title}
    //               <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
    //             </PopoverButton>
    //             <PopoverPanel
    //               transition
    //               className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
    //             >
    //               <div className="p-2">
    //                 {
    //                   (route.subNavigations.map((subRoute: tSubNavigations) => {
    //                     return (
    //                       <div key={`nav-${subRoute.key}`}
    //                         className="group relative flex items-center gap-x-6 rounded-lg p-2 text-lg font-bold hover:bg-gray-50" >
    //                         <a href={subRoute.url} className="block font-semibold text-gray-900">
    //                           { subRoute.title }
    //                           <span className="absolute inset-0" />
    //                         </a>
    //                       </div>
    //                     )
    //                   }))
    //                 }
    //               </div>
    //             </PopoverPanel>
    //           </Popover>
    //         )
    //       }
    //     })
    //   }
    // </PopoverGroup>
  )
}

export default MobileMenuBar;