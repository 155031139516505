import { tCarousel } from '../types';
import i18n from '../i18n';

const carousels: tCarousel[] = [
  {
    imagePath: '/images/carousels/carousel-1.png',
    caption: i18n.t('everyoneDeservesTheOpportunityOfHome')
  },
  {
    imagePath: '/images/carousels/carousel-2.png',
    caption: i18n.t('homeOfTamanSaujanaIdaman')
  },
  {
    imagePath: '/images/carousels/carousel-3.png',
    caption: i18n.t('comingSoonToAClosingNearYou')
  },
  {
    imagePath: '/images/carousels/carousel-4.png',
    caption: i18n.t('theCompanyYouCanTrust')
  },
  {
    imagePath: '/images/carousels/carousel-5.png',
    caption: i18n.t('theCompanyYouCanTrust')
  },
  {
    imagePath: '/images/carousels/carousel-6.png',
    caption: i18n.t('theCompanyYouCanTrust')
  },
]

export default carousels
