import { tRoutes } from '../types';
import i18n from '../i18n';

const routesConfig: tRoutes[] = [
  {
    key: 'home-route',
    title: i18n.t('home'),
    url: '/',
    isEnabled: true,
    component: 'Home',
  },
  {
    key: 'overview-route',
    title: i18n.t('overview'),
    url: '/overview',
    isEnabled: true,
    component: 'Overview',
  },
  {
    key: 'mission-vision-route',
    title: i18n.t('ourMissionVision'),
    url: '/mission-vision',
    isEnabled: true,
    component: 'MissionVision',
  },
  {
    key: 'service-route',
    title: i18n.t('ourService'),
    url: '/services',
    isEnabled: true,
    component: 'Services',
  },
  {
    key: 'project-route',
    title: i18n.t('project'),
    url: '/project/:projectType',
    isEnabled: true,
    component: 'Project',
  },
  {
    key: 'project-detail-route',
    title: i18n.t('project'),
    url: '/project/:projectType/:id',
    isEnabled: true,
    component: 'Project',
  },
  {
    key: 'get-in-touch-route',
    title: i18n.t('getInTouch'),
    url: '/contact',
    isEnabled: true,
    component: 'ContactUs'
  },
  {
    key: 'error-404-route',
    title: i18n.t('error404'),
    url: '*',
    isEnabled: true,
    component: 'Error404',
  },
];

export default routesConfig
