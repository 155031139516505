import { createContext } from 'react';
import { tWebAttributes } from '../types';

export const webAttrContext = createContext<{webAttr: tWebAttributes; setWebAttr: (webAttr: tWebAttributes) => void;}>({
  webAttr: {
    isMobileView: false,
    isTabletView: false,
    isWebView: false,
    contentHeight: window.innerHeight,
    contentWidth: window.innerWidth,
  },
  setWebAttr: (webAttr: tWebAttributes) => {},
});
