import React from 'react';

interface ITag {
  icon?: JSX.Element
  textSize: string
  bgColor: string
  fontColor: string
  value: string
}

const Tag: React.FC<ITag> = ({ icon, textSize, bgColor, fontColor, value }: ITag) => {
  return (
    <span className={`inline-flex items-center my-1 px-2 py-1 me-2 rounded-lg font-medium ${textSize} ${fontColor} ${bgColor}`}>
      { (icon) ? <span className='pr-1'>{icon}</span> : null }
      { value }
    </span>
  )
}

export default Tag