import React from 'react';
import i18n from '../../i18n';
import Tag from '../Tag/Tag';
import { Tooltip } from 'react-tooltip'
import { tProjectDetails } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProjectCard {
  projectType: string|undefined
  projectDetails: tProjectDetails
}

const ProjectCard: React.FC<IProjectCard> = ({ projectType, projectDetails }: IProjectCard) => {

  const generateText = () => {
    return projectDetails.tags.map((tag, index) => {
      return <Tag key={`tag-${index}`} icon={<FontAwesomeIcon icon={['fas', 'hashtag']} />} textSize='text-sm' bgColor='bg-blue-700' fontColor='text-white' value={tag} />;
    })
  }

  return (
    <div className='max-w-full h-full bg-white rounded-lg shadow-lg hover:bg-gray-100 mx-10'>
      <Tooltip id={`tt-${projectDetails.id}`} data-tooltip-float={true}/>
      <a href={`/project/${projectType}/${projectDetails.id}`} className='h-full' data-tooltip-id={`tt-${projectDetails.id}`} data-tooltip-content={i18n.t('clickToViewMore')}>
        <div className='border-b border-gray-200'>
          <img className='rounded-t-lg' src={projectDetails.cover}  alt={projectDetails.title} />
        </div>
        <div className='p-5'>
          <h5 className='uppercase text-xl font-extrabold'>{projectDetails.title}</h5>
          <p className='my-2 line-clamp-3 overflow-hidden text-md font-normal text-gray-700'>{projectDetails.description}</p>
          <div className='my-2'>
            { (projectDetails.location === '') ? null : <Tag icon={<FontAwesomeIcon icon={['fas', 'location-dot']} />} textSize='text-sm' bgColor='bg-red-700' fontColor='text-white' value={projectDetails.location} /> }
            { generateText() }
          </div>
        </div>
      </a>
    </div>
  );

}

export default ProjectCard