import { tNavigations } from '../types';
import i18n from '../i18n';

const navigations: tNavigations[] = [
  {
    key: 'home-route',
    title: i18n.t('home'),
    url: '/',
    isEnabled: true,
    component: 'Home',
    subNavigations: []
  },
  {
    key: 'about-us-route',
    title: i18n.t('aboutUs'),
    url: '',
    isEnabled: true,
    component: '',
    subNavigations: [
      {
        key: 'overview-route',
        title: i18n.t('overview'),
        url: '/overview',
        isEnabled: true,
        component: 'Overview',
      },
      {
        key: 'mission-vision-route',
        title: i18n.t('ourMissionVision'),
        url: '/mission-vision',
        isEnabled: true,
        component: 'MissionVision',
      },
      {
        key: 'service-route',
        title: i18n.t('ourService'),
        url: '/services',
        isEnabled: true,
        component: 'Services',
      }
    ]
  },
  {
    key: 'project-route',
    title: i18n.t('project', {count: 3}),
    url: '',
    isEnabled: true,
    component: '',
    subNavigations: [
      {
        key: 'current-project-route',
        title: i18n.t('currentProject'),
        url: '/project/current',
        isEnabled: true,
        component: 'Project'
      },
      {
        key: 'future-project-route',
        title: i18n.t('futureProject'),
        url: '/project/future',
        isEnabled: true,
        component: 'Project'
      },
      {
        key: 'completed-project-route',
        title: i18n.t('completedProject'),
        url: '/project/completed',
        isEnabled: true,
        component: 'Project'
      },
      {
        key: 'construction-project-route',
        title: i18n.t('constructionProject'),
        url: '/project/construction',
        isEnabled: true,
        component: 'Project'
      }
    ]
  },
  {
    key: 'get-in-touch-route',
    title: i18n.t('getInTouch'),
    url: '/contact',
    isEnabled: true,
    component: 'ContactUs',
    subNavigations: []
  }
];

export default navigations
