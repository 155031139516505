import React from 'react';
import i18n from '../../i18n';
import navigations from '../../data/navigations';
import moment from 'moment-timezone'
import { head, filter } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer: React.FC = () => {
  const aboutUsNav = head(filter(navigations, { 'key': 'about-us-route' }));
  const projectNav = head(filter(navigations, { 'key': 'project-route' }));

  return (
    <footer className='bg-slate-900'>
      <div className='mx-auto w-full max-w-7xl text-white p-7 px-2 md:p-6 lg:p-6'>
        <div className="grid grid-rows-1 grid-cols-12 gap-4">
          <div className='col-span-12 lg:col-span-6'>
            <div className='text-md'>
              <div className='flex py-3 pb-5'>
                <div className='pr-3'>
                  <a href='https://www.facebook.com/detikventures' target='_blank' rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'facebook-square']} size={'2x'} />
                  </a>
                </div>
                <div className='px-3'>
                  <a href='https://www.tiktok.com/@detikventure' target='_blank' rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'tiktok']} size={'2x'} />
                  </a>
                </div>
              </div>
              <div>
                { i18n.t('copyrightFooter', { year: moment().year() }) }
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-3'>
            <div className='text-xl font-extrabold'>{ i18n.t('aboutUs') }</div>
            {
              aboutUsNav && aboutUsNav.subNavigations.map((route) => {
                return (
                  <div key={route.key} className='text-md font-semibold py-2'>
                    <a href={route.url}>
                      { route.title }
                    </a>
                  </div>
                )
              })
            }
          </div>
          <div className='col-span-12 lg:col-span-3'>
            <div className='text-xl font-extrabold mb-1'>{ i18n.t('project') }</div>
            {
              projectNav && projectNav.subNavigations.map((route) => {
                return (
                  <div key={route.key} className='text-md font-semibold py-2'>
                    <a href={route.url}>
                      { route.title }
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;