import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';
import { useWebAttr } from '../../hooks';

interface IBaseLayout {
  children: React.ReactNode
}

const BaseLayout:React.FC<IBaseLayout> = ({ children }: IBaseLayout) => {
  const { setWebAttr } = useWebAttr();

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      const isMobile = window.innerWidth <= 320;
      const isTabletView = window.innerWidth <= 768;
      const isWebView = window.innerWidth <= 1280;

      setWebAttr({
        isMobileView: isMobile,
        isTabletView: isTabletView,
        isWebView: isWebView,
        contentHeight: window.innerHeight,
        contentWidth: window.innerWidth
      });
    });
  }, []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='z-50'>
        <NavigationBar />
      </div>
      <div className='grow base-layout-content'>
        { React.cloneElement(children as React.ReactElement<any>)}
      </div>
      <div className='z-50'>
        <Footer />
      </div>
    </div>
  )
}

export default React.memo(BaseLayout)