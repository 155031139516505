import React from 'react';
import carousels from '../../data/home';
import Carousel from 'react-multi-carousel';
import { tCarousel } from '../../types';

const Home: React.FC = () => {
  const responsiveSettngs = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }

  return(
    <div className='mx-auto h-full w-full'>
      <Carousel arrows={true} showDots={false} slidesToSlide={1} pauseOnHover={true} autoPlay={true} shouldResetAutoplay={true}
        className='h-full' infinite={true} responsive={responsiveSettngs} autoPlaySpeed={2000} swipeable={true}>
        {
          carousels.map((carousel: tCarousel, index: number) => {
            return (
              <div key={`carousel-${index}`} className='h-full'>
                <div className='carousel-caption-cover'>
                  <div className='text-white font-bold p-2 text-sm lg:p-4 lg:text-5xl'>{carousel.caption}</div>
                </div>
                <img src={carousel.imagePath} className='object-fill h-full w-full' />
              </div>
            );
          })
        }
      </Carousel>
    </div>
  );
}

export default React.memo(Home);
