import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// locales
import enUS from './locales/en-US.json'
import msMy from './locales/ms-MY.json'
import zhCN from './locales/zh-CN.json'
import zhTW from './locales/zh-TW.json'

// export i18n
i18n.use(initReactI18next).init({
  resources: {
    en_US: {
      translations: enUS,
    },
    ms_My: {
      translations: msMy,
    },
    zh_CN: {
      translations: zhCN,
    },
    zh_TW: {
      translations: zhTW,
    },
  },
  fallbackLng: 'en_US',
  debug: false,
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false,
  },
})

export default i18n;
