import React from 'react';
import projects from '../../data/projects';
import { useParams } from 'react-router';
import { head, filter } from 'lodash';
import { ProjectCard } from '../../components';
import ProjectDetails from './components/ProjectDetails';
import Carousel from 'react-multi-carousel';

const Project: React.FC = () => {
  const { projectType, id } = useParams();
  const projectData = head(filter(projects, { 'key': `${projectType}-project`.toLowerCase() }))
  const responsiveSettngs = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };


  if (id) {
    const projectSelected = head(filter(projectData?.projects, { 'id': id }));

    return(
      <div className='min-h-full my-5 animate-fade animate-once animate-duration-[2000ms] animate-ease-in-out'>
        <div className='min-h-full mx-auto max-w-7xl px-6 md:px-8 lg:px-10 overview-content content-center'>
          <ProjectDetails projectType={projectType} projectDetails={projectSelected} />
        </div>
      </div>
    );
  } else {
    return(
      <div className='h-full my-5 animate-fade animate-once animate-duration-[2000ms] animate-ease-in-out'>
        <div className='h-full mx-auto px-6 md:px-8 lg:px-10 overview-content content-center'>
          {
            (projectData == undefined) ? <div>No Data...</div> :
              <div className='pb-3 relative'>
                <Carousel infinite={true} arrows={true} showDots={true} slidesToSlide={1} renderDotsOutside={true}
                  responsive={responsiveSettngs} autoPlay={false} shouldResetAutoplay={true} autoPlaySpeed={2000}
                  dotListClass='dot-list-outside' swipeable={true} rtl={false}>
                  {
                    projectData.projects.map((proj) => {
                      return (
                        <ProjectCard key={`projDetails-${proj.id}`} projectType={projectType} projectDetails={proj} />
                      );
                    })
                  }
                </Carousel>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default React.memo(Project);
